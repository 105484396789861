<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field2
        :fields="fields"
        :row-data="row_data"
        post-api="/kioskmanage/AddKiosk"
        finish-router="kioskEnvironment"
        permission="Kiosk_Menu_Environment"
        @beforeSubmit="beforeSubmit"
      >
      </edit-field2>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData, availableShowsOptions, availableLoginOptions } from './fields'
import EditField2 from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import { flattenObject, expandObject } from '@/libs/ez-utils';

export default {
  name: 'KioskEnvAdd',
  components: {
    EditFieldHeader,
    EditField2,
    fields,
  },

  data() {
    return {
      title: common.getMenuName('kioskEnvironment') + ' - ' + common.getI18n('common.add'),
      fields,
      availableShows: availableShowsOptions.map(option => option.value),
      availableLoginTypes: availableLoginOptions.map(option => option.value),
      row_data: {
        _positiveShows: [],
        _positiveLoginTypes: [],
      },
      row_data_default: {
        'baseInfo': {
          'kioskToken': '',
          'kioskName': '',
          'kioskEnvironment': null,
          'kioskPlatform': null,
        },
        'measureConfig': {
          'isShowCognition': false,
          'isShowGripStrength': false,
          'isShowHeight': true,
          'isShowWeight': true,
          'isShowSpO2': true,
          'isShowBP': true,
          'isShowBodyTemp': true,
          'isShowGlucose': false,
          'isLoginFaceID': false,
          'isLoginQrCode': true,
          'isLoginNFC': true,
          'spO2SampleSize': 1,
          'spO2Range': 2,
          'gripStrengthSampleSize': 10,
          'gripStrengthBackgroundSampleSize': 10,
          'gripStrengthRange': 30,
          'gripStrengthMinimumIncrease': 0.1,
          'bodyTempMinimum': 35,
          'bodyTempRange': 1,
          'bodyTempSampleSize': 1,
          'heightMinimum': 130,
          'heightRange': 2,
          'heightOffsetM': 1,
          'heightOffsetC': 0,
          'heightSampleSize': 5,
          'heightMeasureDelaySeconds': 3,
          'weightMinimum': 30,
          'weightRange': 2,
          'weightOffsetM': 1,
          'weightOffsetC': 0,
          'weightSampleSize': 10,
          'weightMeasureDelaySeconds': 3,
          'faceIdentificationInterval': 750,
          'faceIdConfidenceFilter': 0.7,
          // 'faceCollectionID': '',
          'heightOfSensor': 206,
          'welPageWeightTimeSpan': 20000,
          'resultPageDisplayDuration': 20000,
          'measureSwitchDuration': 7000,
        },
      },
    }
  },

  created() {
    reloadFieldData()
  },

  mounted() {
    // Extract the fields starting with 'isShow' in row_data_default as options for _positiveShows
    const modulesAndStatus = Object.entries(this.row_data_default.measureConfig).filter(v => /^isShow.+$/.test(v[0]))
    const _positiveShows = modulesAndStatus.filter(v => v[1]).map(v => v[0])

    // Extract the fields starting with 'isLogin' in row_data_default as options for _positiveLoginTypes
    const loginTypes = Object.entries(this.row_data_default.measureConfig).filter(v => /^isLogin.+$/.test(v[0]))
    const _positiveLoginTypes = loginTypes.filter(v => v[1]).map(v => v[0])

    // Merge options into row_data
    this.row_data = Object.assign({ _positiveShows, _positiveLoginTypes }, flattenObject(this.row_data_default))
  },

  methods: {
    beforeSubmit(callback) {
      const rowData = expandObject(JSON.parse(JSON.stringify(this.row_data)))

      // Convert _positiveShows to isShowXXX
      this.availableShows.forEach(isShowModule => {
        rowData.measureConfig[isShowModule] = this.row_data._positiveShows.includes(isShowModule)
      })
      delete rowData._positiveShows

      // Convert _positiveLoginTypes to isLoginXXX
      this.availableLoginTypes.forEach(loginType => {
        rowData.measureConfig[loginType] = this.row_data._positiveLoginTypes.includes(loginType)
      })
      delete rowData._positiveLoginTypes

      callback(true, rowData)
    },
  },
}
</script>

<style scoped>

</style>
